/**======================================================================
=========================================================================
Template Name: Datta Able Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
@import '~bootstrap/dist/css/bootstrap';
// General theme contents
@import 'partials/variables';
@import 'partials/general';
@import 'partials/generic';
@import 'partials/mixins/function';

// icon
@import 'partials/font/next-icon';
@import 'partials/font/feather';
@import 'partials/font/fontawesome';
@import 'partials/font/flaticon';
@import 'partials/font/cryptofont';

// important Element
@import 'partials/menu/menu-lite';
@import 'partials/widget/widget';

// Theme Element
@import 'partials/theme-elements/form';
@import 'partials/theme-elements/radiobox-checkbox';
@import 'partials/theme-elements/labels-badges';
@import 'partials/theme-elements/data-tables';
@import 'partials/theme-elements/authentication';
@import 'partials/theme-elements/button';
@import 'partials/theme-elements/breadcrumb-pagination';
@import 'partials/theme-elements/nav';

// Other
@import 'partials/other/chat';
@import 'partials/other/chart';


@import 'partials/third-party/third-party';

.blabla {
  color: $menu-dark-background;
  font-weight: bold;
}

article {
  margin: 1rem
}

.section__grid {
  display: block;
  padding: 2rem;
}

.section__box {
  position: relative;
  height: 100%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 253, 0.1) inset;
  overflow: hidden;
}

.section__box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  transform: skewX(45deg) translateX(600%);
  transition: 0.5s;
}

.section__box:hover::before {
  transform: skewX(45deg) translateX(-200px);
  transition: 0.5s;
}

.section__content {
  position: relative;
  padding: 1rem;
  height: 100%;
  backdrop-filter: blur(10px);
}

.section__content h3 {
  margin: 0 0 1rem;
  padding: 0;
  font-size: 0.75rem;
  color: black;
  font-weight: 500;
}

.section__content p {
  margin: 0;
  padding: 0;
  font-weight: 700;
  color: black;
}

.section__content p:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .section__grid {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 998px) {
  .section__grid {
    grid-gap: 2rem;
  }
}

@media screen and (min-width: 1400px) {
  .section__grid {
    grid-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1640px) {
  .section__grid {
    grid-gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
  }
}

.mapbox {
  position: relative;
  height: 250px;
  width: 96%;
  border-radius: 1rem;
  margin-bottom: 30px;
  border: 1px solid #ccc;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 253, 0.2);
  overflow: hidden;
}

.mapbox figure {
  height: 100%;
}

.mapbox iframe {
  width: 100%;
  height: 100%;
  border: none;
  filter: grayscale(1) invert(0);
  border-radius: 0.5rem;
}

@media (min-width: 580px) {
  .mapbox {
    height: 460px;
  }
}

@media (min-width: 768px) {
  .mapbox {
    height: 560px;
  }
}

@media (min-width: 988px) {
  .mapbox {
    height: 660px;
  }
}

@media (min-width: 1200px) {
  .mapbox {
    height: 760px;
  }
}

.cardWrapper  {
  max-width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 1400px) {
      max-width: 800px;
  }
}

.cardHero {
  width: 100%
}



/*************************** main-video begin**************************/

.main__section {
  position: relative;
  height: 650px;
}

iframe.main-video {
  position: absolute;
  right: 0;
  top: 65%;
  width: 500px;
  height: 280px;
}

@media (max-width: 500px) {
  iframe.main-video {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 280px;
  }
}

  
/*************************** main-video end**************************/